import { useState } from 'react';

const useModal = () => {
  const [isShowing, setIsShowing] = useState(false);
  const [id, setId] = useState<number | null>(null);

  function toggle(id: number | null) {
    setId(id);
    setIsShowing(!isShowing);
  }

  return {
    isShowing,
    toggle,
    id,
  };
};

export default useModal;
