import {
  forwardRef, memo, useEffect, useImperativeHandle, useRef, useState
} from 'react';
import { type ICellEditorParams } from 'ag-grid-enterprise';
import '../../components/grid/styles/numeric-editor.css';

const KEY_BACKSPACE = 'Backspace';
const KEY_DELETE = 'Delete';
const KEY_F2 = 'F2';
const KEY_ENTER = 'Enter';
const KEY_TAB = 'Tab';

interface NumericEditorProps extends ICellEditorParams {
  allowDecimals?: boolean;
}

const NumericEditor = memo(forwardRef((props: NumericEditorProps, ref) => {
  const { allowDecimals, } = props;

  const isCharNumeric = (charStr: string) => {
    if (allowDecimals) {
      return /\d|\.|-/.test(charStr);
    }
    return /\d|-/.test(charStr);
  };

  const createInitialState = () => {
    let startValue;
    let highlightAllOnFocus = true;

    if (props.eventKey === KEY_BACKSPACE || props.eventKey && !isCharNumeric(props.eventKey)) {
      // if backspace or delete pressed, we clear the cell
      startValue = '';
    } else if (props.eventKey) {
      // if a letter was pressed, we start with the letter
      startValue = props.eventKey;
      highlightAllOnFocus = false;
    } else {
      // otherwise we start with the current value
      startValue = props.value;
      if (props.eventKey === KEY_F2) {
        highlightAllOnFocus = false;
      }
    }

    return {
      value: startValue,
      highlightAllOnFocus,
    };
  };

  const initialState = createInitialState();
  const [value, setValue] = useState(initialState.value);
  const [highlightAllOnFocus, setHighlightAllOnFocus] = useState(initialState.highlightAllOnFocus);
  const refInput = useRef<HTMLInputElement>(null);

  // focus on the input
  useEffect(() => {
    // get ref from React component
    const eInput = refInput.current!;
    eInput.focus();
    if (highlightAllOnFocus) {
      eInput.select();

      setHighlightAllOnFocus(false);
    } else {
      // when we started editing, we want the caret at the end, not the start.
      // this comes into play in two scenarios:
      //   a) when user hits F2
      //   b) when user hits a printable character
      const length = eInput.value ? eInput.value.length : 0;
      if (length > 0) {
        eInput.setSelectionRange(length, length);
      }
    }
  }, []);

  /* Utility Methods */
  const isLeftOrRight = (event: any) => ['ArrowLeft', 'ArrowRight'].includes(event.key);

  const isKeyPressedNumeric = (event: any) => {
    const charStr = event.key;
    return isCharNumeric(charStr);
  };

  const isBackspaceOrDelete = (event: any) => event.key === KEY_BACKSPACE || event.key === KEY_DELETE;

  const finishedEditingPressed = (event: any) => {
    const { key, } = event;
    return key === KEY_ENTER || key === KEY_TAB;
  };

  const onKeyDown = (event: any) => {
    if (isLeftOrRight(event) || isBackspaceOrDelete(event)) {
      event.stopPropagation();
      return;
    }

    if (!finishedEditingPressed(event) && !isKeyPressedNumeric(event)) {
      if (event.preventDefault) event.preventDefault();
    }

    if (finishedEditingPressed(event)) {
      props.stopEditing();
    }
  };

  /* Component Editor Lifecycle methods */
  useImperativeHandle(ref, () => ({
    // the final value to send to the grid, on completion of editing
    getValue() {
      if (value) {
        return allowDecimals ? parseFloat(value) : parseInt(value);
      }
      return null;
    },

  }));

  return (
    <input
      ref={refInput}
      value={value}
      onChange={(event: any) => { setValue(event.target.value); }}
      onKeyDown={(event: any) => { onKeyDown(event); }}
      className="numeric-input"
    />
  );
}));

export default NumericEditor;
