import { useFormikContext } from 'formik';
import { Button, Cascader, CascaderProps } from 'antd';
import { useLazyGetTenantDatabasesQuery } from '../api/api';
import { useState } from 'react';
import { BaseOptionType } from 'antd/es/cascader';
import { objectByString } from '../../../utility/Utils';
import { Message } from '../../../utility/notifications/Message';
import { OptionValueLabelDto } from '../../../api/data-contracts';
import AppFormLabel from '../../components/forms/AppFormLabel';

interface Option {
  value?: string | number | null;
  label: React.ReactNode;
  children?: Option[];
  isLeaf?: boolean;
}

interface Props {
  databaseList: OptionValueLabelDto[];
}

function transformDatabaseOptions(backendData: OptionValueLabelDto[]) {
  return backendData.map(option => ({
    ...option,
    isLeaf: false
  }));
}

function DatabaseSettings({ databaseList }: Props) {
  const { values, setFieldValue } = useFormikContext();

  const [options, setOptions] = useState<Option[]>(transformDatabaseOptions(databaseList));

  const [getDatabases] = useLazyGetTenantDatabasesQuery();

  function onChange(value: BaseOptionType, e: any) {
    if (value[1]) {
      setFieldValue("databaseName", value[1]);
    }
    else if (value[0]) {
      setFieldValue("databaseServer", value[0]);
    }
  };

  const loadData = async (selectedOptions: Option[]) => {
    const targetOption = selectedOptions[selectedOptions.length - 1];

    if (!targetOption.value)
      return;

    await getDatabases({ serverName: targetOption.value.toString() })
      .unwrap()
      .then(result => {
        targetOption.children = result;
        setOptions([...options]);
      })
      .catch((e) => {
        void Message("Error loading databases", e.data.exception, "error");
      });
  };

  return (
    <>
      <div className="separator mb-4" />

      <div className="fv-row">
        <AppFormLabel label={"Database name & server"} required={true} />


        <Cascader size={"large"}
          value={[objectByString(values, "databaseServer"), objectByString(values, "databaseName")]}
          className='w-100' options={options} loadData={loadData} onChange={onChange} changeOnSelect />
      </div>
    </>
  );
}

export default DatabaseSettings;
