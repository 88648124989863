import { type Transform } from '@dnd-kit/utilities';
import React, { useEffect } from 'react';
import { type DraggableSyntheticListeners } from '@dnd-kit/core';
import clsx from 'clsx';
import { useListView } from '../../../../../utility/ListView';
import { type ViewGroupItem } from '../../api/types';

export interface Props {
  dragOverlay?: boolean;
  color?: string;
  disabled?: boolean;
  dragging?: boolean;
  handle?: boolean;
  height?: number;
  index?: number;
  fadeIn?: boolean;
  transform?: Transform | null;
  listeners?: DraggableSyntheticListeners;
  sorting?: boolean;
  style?: React.CSSProperties;
  transition?: string | null;
  wrapperStyle?: React.CSSProperties;
  value: React.ReactNode;
  viewGroupItem: ViewGroupItem;

  onRemove?: () => void;

  renderItem?: (args: {
    dragOverlay: boolean;
    dragging: boolean;
    sorting: boolean;
    index: number | undefined;
    fadeIn: boolean;
    listeners: DraggableSyntheticListeners;
    ref: React.Ref<HTMLElement>;
    style: React.CSSProperties | undefined;
    transform: Props['transform'];
    transition: Props['transition'];
    value: Props['value'];
  }) => React.ReactElement;
}

export const ViewGroupItemComponent = React.memo(
  React.forwardRef<HTMLLIElement, Props>(
    (
      {
        viewGroupItem,
        color,
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        handle,
        height,
        index,
        listeners,
        onRemove,
        renderItem,
        sorting,
        style,
        transition,
        transform,
        value,
        wrapperStyle,
        ...props
      },
      ref
    ) => {
      const { toggle, } = useListView();

      useEffect(() => {
        if (!dragOverlay) {
          return;
        }

        document.body.style.cursor = 'grabbing';

        return () => {
          document.body.style.cursor = '';
        };
      }, [dragOverlay]);

      return renderItem
        ? (
          renderItem({
            dragOverlay: Boolean(dragOverlay),
            dragging: Boolean(dragging),
            sorting: Boolean(sorting),
            index,
            fadeIn: Boolean(fadeIn),
            listeners,
            ref,
            style,
            transform,
            transition,
            value,
          })
        )
        : (
          <li
            key={viewGroupItem.id}
            className="m-5 list-inline-item"
            style={
              {
                width: '350px',
                ...wrapperStyle,
                transition,
                '--translate-x': transform ? `${Math.round(transform.x)}px` : undefined,
                '--translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
                '--scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
                '--scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
                '--index': index,
                '--color': color,
              } as React.CSSProperties
            }
            ref={ref}
          >
            <div
              style={{ ...style, height: '200px', }}
              className={clsx(
                'card card-custom shadow ',
                handle && 'overlay overlay-block',
                handle && 'opacity-80'
              )}
            >
              {handle && (
                <div {...listeners} className="overlay-layer card-rounded">
                  <i className="las la-arrows-alt fs-3x text-dark" />
                </div>
              )}

              <div className="card-body d-flex align-items-center pt-3 pb-0">
                <div className="d-flex col-10 flex-column flex-grow-1 py-2 py-lg-13 me-2">
                  <h1
                    className={clsx(
                      'fw-bold text-dark fs-4 mb-2 text-hover-primary text-truncate',
                      handle && 'opacity-20'
                    )}
                  >
                    {viewGroupItem.name}
                  </h1>
                </div>

                <img
                  alt=""
                  className={clsx('calign-self-center h-60px', handle && 'opacity-20')}
                  src="https://sinceriusdev.mk2softwaredev.nl/Common/Images/dashboards.png"
                />
              </div>

              <div className="card-footer pt-4">
                <button
                  type="button"
                  onClick={() => { toggle(viewGroupItem.itemId); }}
                  className={clsx('btn btn-primary w-100', handle && 'disabled opacity-20')}
                >
                  Edit
                </button>
              </div>
            </div>
          </li>
        );
    }
  )
);
