import {
  useCallback, useEffect, useMemo, useState
} from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import { type ColDef, type GetRowIdParams, type GridApi, type GridReadyEvent } from 'ag-grid-community';
import { cloneDeep } from 'lodash';
import {
  actionReplacement,
  type BiHubPipelineDto,
  type BiHubPowerBiDatasetPutDto,
  type BiHubTaskPutDto,
  DataTypesMap
} from '../../../../bi-hub/api/types';
import IsRouteOrActionAllowed from '../../../../../utility/IsRouteOrActionAllowed';
import * as Permission from '../../../../../constants/permissions';
import FormButton from '../../../../components/forms/AppFormButton';
import ActionButton from '../../../../components/buttons/ActionButton';
import { getDefaultContextMenu, processDataFromClipboard } from '../../../../../utility/AgUtils';
import { columnsParallel, columnsSequential, getColumnsPowerBi } from '../../../components/BiHubEditColumns';
import AppToolTip from '../../../../components/core/AppToolTip';
import { popoverElementBiHub } from './PopoverElementBiHub';
import { useGetAvailablePowerBiReportsDropdownQuery } from '../../../../content-manager/PowerBi/api/api';
import { Message } from '../../../../../utility/notifications/Message';

interface Props {
  tenantId: number;
  initialData: BiHubPipelineDto;
  cancel: (id: number | null) => void;
  loading: boolean;
  handleSubmit: (updateData: {
    tasks: BiHubTaskPutDto[];
    datasets: BiHubPowerBiDatasetPutDto[];
  }) => void;
}

function AgGridHubTable({
  tenantId, cancel, loading = false, handleSubmit, initialData,
}: Props) {
  const {
    data: dropdownPowerBiReports,
    isLoading,
  } = useGetAvailablePowerBiReportsDropdownQuery(tenantId);
  const [parallelApi, setParallelApi] = useState<GridApi | null>(null);
  const [sequentialApi, setSequentialApi] = useState<GridApi | null>(null);
  const [initialParallelData] = useState(cloneDeep(initialData.tasks));
  const [initialSeqData] = useState(cloneDeep(initialData.sequentialTasks));
  const [initialPowerBiData] = useState(cloneDeep(initialData.powerBiDatasets));
  const [powerBiReportsApi, setPowerBiReportsApi] = useState<GridApi | null>(null);

  const onPowerBiReportsGridReady = (params: GridReadyEvent) => {
    setPowerBiReportsApi(params.api);

    params.api.setDomLayout('autoHeight');
  };

  const rowIdSequence = 100;

  const defaultColDef = useMemo<ColDef>(() => ({
    flex: 1,
    width: 100,
    sortable: true,
    filter: true,
    resizable: true,
  }), []);

  const formatChangesAndSubmit = () => {
    let tasks: BiHubTaskPutDto[] = [];
    const datasets: BiHubPowerBiDatasetPutDto[] = [];
    let index = 1;

    parallelApi!.stopEditing();
    sequentialApi!.stopEditing();
    powerBiReportsApi!.stopEditing();

    parallelApi!.forEachNode((node) => {
      tasks.push({ ...node.data, runParallel: true, sorting: 0, });
    });

    sequentialApi!.forEachNode((node) => {
      tasks.push({ ...node.data, runParallel: false, sorting: index, });
      index += 1;
    });

    powerBiReportsApi!.forEachNode((node) => {
      if (node.data.newlyAdded) {
        delete node.data.id;
        delete node.data.newlyAdded;
      }

      datasets.push({
        powerBiReportId: node.data.powerBiReportId,
        id: node.data.id,
        isEnabledForTenant: node.data.isEnabledForTenant,
        powerBiReportDisplayName: node.data.powerBiReportDisplayName,
      });
    });

    tasks = tasks.map((task: any) => {
      const targetValue = task.type;

      for (const [key, value] of DataTypesMap.entries()) {
        if (value === targetValue) {
          task.type = key;
          break;
        }
      }

      if (task.newlyAdded) {
        delete task.id;
        delete task.newlyAdded;
      }
      return task;
    });

    handleSubmit({ tasks, datasets, });
  };

  const createDataItem = useCallback(
    (runParallel: boolean) => ({
      id: `${crypto.randomUUID()}-generatedId`,
      runParallel,
      action: '',
      type: Array.from(DataTypesMap.values())[0],
      newlyAdded: true,
      name: '',
      isEnabledForTenant: true,
    }),
    [rowIdSequence]
  );

  const createPowerBiItem = useCallback(
    () => ({
      id: `${crypto.randomUUID()}-generatedId`,
      newlyAdded: true,
      isEnabledForTenant: true,
    }),
    [rowIdSequence]
  );

  const getRowId = (params: GetRowIdParams) => params.data.id;

  const addedItemCallback = useCallback(
    (runParallel: any, api: any) => {
      const addedRowIndex = api!.getDisplayedRowCount() - 1;

      api!.startEditingCell({
        rowIndex: addedRowIndex,
        colKey: 'name',
      });
    },
    []
  );

  const addRecordToGrid = (side: 'parallel' | 'sequential', data: any) => {
    const api = side === 'parallel' ? parallelApi : sequentialApi;
    const rowAlreadyInGrid = !!api!.getRowNode(data.id);

    if (rowAlreadyInGrid) {
      console.log('not adding row to avoid duplicates in the grid');
      return;
    }

    api!.applyTransactionAsync({ add: [data], }, (event) => { addedItemCallback(event, api); });
  };

  const onFactoryButtonClick = (e: 'parallel' | 'sequential') => {
    const data = createDataItem(e === 'parallel');

    parallelApi!.stopEditing();
    sequentialApi!.stopEditing();

    addRecordToGrid(e, data);
  };

  const addPowerBI = () => {
    if (dropdownPowerBiReports && dropdownPowerBiReports.length > 0) {
      const data = createPowerBiItem();
      powerBiReportsApi!.stopEditing();
      powerBiReportsApi!.applyTransactionAsync(
        { add: [data], },
        (event) => { addedItemCallback(event, powerBiReportsApi); }
      );
    } else {
      Message('Error', 'This tenant does not have any reports', 'warningNoBtns');
    }
  };

  const addGridDropZone = (side: string, api: GridApi) => {
    const [dropApi, dropApi2] = side === 'parallel' ? [sequentialApi, parallelApi] : [parallelApi, sequentialApi];
    const dropZone = dropApi!.getRowDropZoneParams({
      onDragStop(e) {
        const { nodes, } = e;
        dropApi2!.applyTransaction({
          remove: nodes,
        });
      },
    });

    api.addRowDropZone(dropZone);
    api.setDomLayout('autoHeight');
  };

  useEffect(() => {
    if (sequentialApi && parallelApi) {
      addGridDropZone('sequential', sequentialApi);
      addGridDropZone('parallel', parallelApi);
    }
  }, [sequentialApi, parallelApi]);

  const onGridReady = (side: string, params: GridReadyEvent) => {
    if (side === 'parallel') {
      setParallelApi(params.api);
    } else {
      setSequentialApi(params.api);
    }
  };

  const onRowDragEnd = () => {
    if (sequentialApi) { sequentialApi.redrawRows(); }
  };

  const getGridWrapper = (id: number) => (
    <>
      <div className="ag-theme-alpine" style={{ minHeight: '50px', }}>
        <AgGridReact
          singleClickEdit
          onRowDragEnd={onRowDragEnd}
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          rowDragManaged
          processDataFromClipboard={processDataFromClipboard}
          suppressMoveWhenRowDragging
          animateRows
          rowData={id === 0 ? initialParallelData : initialSeqData}
          columnDefs={id === 0 ? [...columnsParallel] : [...columnsSequential]}
          tooltipShowDelay={0}
          getContextMenuItems={(params) => (id === 0
            ? getDefaultContextMenu(params, parallelApi)
            : getDefaultContextMenu(params, sequentialApi)
          )}
          onGridReady={(params) => { onGridReady(id === 0 ? 'parallel' : 'sequential', params); }}
        />
      </div>

      <div>
        <ActionButton
          onClick={(event) => {
            event.preventDefault();
            onFactoryButtonClick(id === 0 ? 'parallel' : 'sequential');
          }}
          className="w-auto mt-6"
          text={id === 0 ? 'Add new parallel task' : 'Add new sequential task'}
          color="secondary"
          fontIcon="fas la-plus"
        />
      </div>
    </>
  );
  const getPowerBiReportsGridWrapper = () => (
    <>
      <div className="ag-theme-alpine" style={{ minHeight: '50px', }}>
        <AgGridReact
          defaultColDef={defaultColDef}
          getRowId={getRowId}
          suppressLoadingOverlay={!isLoading}
          rowData={initialPowerBiData}
          columnDefs={getColumnsPowerBi(dropdownPowerBiReports!)}
          tooltipShowDelay={0}
          onGridReady={onPowerBiReportsGridReady}
        />
      </div>
      <div>
        <ActionButton
          onClick={(event) => {
            event.preventDefault();
            addPowerBI();
          }}
          className="w-auto mt-6"
          text="Add new powerBi Report"
          color="secondary"
          fontIcon="fas la-plus"
        />
      </div>
    </>
  );

  return (
    <div className="d-flex flex-column justify-content-between h-auto">
      <div className="d-flex justify-content-between w-100 pt-4">
        <div>
          <h3>Parallel tasks</h3>
          <div className="mb-2">
            Tasks will run continuously at the same time
            <b>
              (these tasks will run first)
            </b>
          </div>
        </div>

        <div>
          <AppToolTip text={`${actionReplacement} info`} popoverElement={popoverElementBiHub} />
        </div>
      </div>

      {getGridWrapper(0)}

      <div className="d-flex justify-content-between w-100 pt-10">
        <div>
          <h3>Sequential tasks</h3>
          <div className="mb-2">Task will be run in a following order</div>
        </div>

        <div>
          <AppToolTip text={`${actionReplacement} info`} popoverElement={popoverElementBiHub} />
        </div>
      </div>

      {getGridWrapper(1)}

      <div className="d-flex justify-content-between w-100 pt-10">
        <div>
          <h3>Power BI dataset refresh</h3>
        </div>
      </div>

      {dropdownPowerBiReports &&
        getPowerBiReportsGridWrapper()}

      <div className="separator border-3 my-5" />
      <div className="text-end pt-5 d-flex flex-row-reverse justify-content-between">
        {IsRouteOrActionAllowed.IsRouteOrActionAllowed(Permission.Host_Tenants_Edit) && (
          <div>
            <button
              type="reset"
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
              onClick={() => { cancel(null); }}
              disabled={loading}
            >
              Cancel
            </button>
            <FormButton
              width="w-20"
              type="button"
              text="Submit"
              state={false}
              color="primary"
              disabled={loading}
              onClick={formatChangesAndSubmit}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default AgGridHubTable;
