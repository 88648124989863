import { createRoot } from 'react-dom/client';
// Axios
import { Chart, registerables } from 'chart.js';
// Apps
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 * */
import './_metronic/assets/sass/style.scss';
import './_metronic/assets/sass/plugins.scss';
import './_metronic/assets/sass/style.react.scss';
import { Provider } from 'react-redux';

import {
  createBrowserRouter,
  RouterProvider
} from 'react-router-dom';
import { ConfigProvider } from 'antd';

import { LicenseManager } from 'ag-grid-enterprise';
import { AgCharts } from 'ag-charts-enterprise';
import { getRoutes } from './app/routing';
import { store } from './app/redux/store';
import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n';
import { SignalRProvider } from './app/utility/helpers/SignalRProvider';
import SignalRConsumer from './app/utility/helpers/SignalRConsumer';

LicenseManager.setLicenseKey(process.env.REACT_APP_AGGRID_LICENSE ?? '');
AgCharts.setLicenseKey('your license key');

const { PUBLIC_URL, } = process.env;

/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */
Chart.register(...registerables);

Chart.register(...registerables);
const router = createBrowserRouter(getRoutes('blank'), { basename: PUBLIC_URL, });

const container = document.getElementById('root');
if (container) {
  createRoot(container).render(
    <Provider store={store}>
      <ConfigProvider
        theme={{
          components: {
            Select: {
              zIndexPopup: 1100,
            },
            DatePicker: {
              zIndexPopup: 1100,
            },
          },
        }}
      >
        <SignalRProvider
          url={process.env.REACT_APP_API_BASE_URL}
        >
          <MetronicI18nProvider>
            <RouterProvider router={router} />
            <SignalRConsumer />

          </MetronicI18nProvider>
        </SignalRProvider>
      </ConfigProvider>
    </Provider>
  );
}
