import { type ReactElement, ReactNode } from 'react';
import { type buttonColors } from '../../../types';
import { Message } from '../../../utility/notifications/Message';

export interface IAppFormButtonProps {
  type?: "submit" | "reset" | "button" | undefined;
  text: string;
  disabled: boolean;
  state: boolean;
  color: buttonColors;
  width?: string;
  onClick: () => void;
  title?: string;
  popConfirm?: boolean;
}

export default function AppFormButton({ type, color, disabled, onClick, state, text, title = 'Are you sure you want to delete this item', width = 'w-100', popConfirm, }: IAppFormButtonProps): ReactElement {
  return (
    <button
      type={type === "submit" ? "submit" : "button"}
      className={`btn btn-lg btn-${color} ${width}`}
      disabled={disabled}
      onClick={
        async () => {
          if (type === 'reset' || popConfirm) {
            const result = await Message('Confirm', title, 'warning');
            if (result.isConfirmed) {
              onClick();
            }
          } else {
            onClick();
          }
        }}
    >
      {!state && <span className='indicator-label'>{text}</span>}
      {state && (
        <span className='indicator-progress d-block' >
          Please wait...
          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
        </span>
      )}
    </button>
  );
}
