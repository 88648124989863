import React, { forwardRef } from 'react';
import clsx from 'clsx';
import { type buttonColors } from '../../../types';
import { Message } from '../../../utility/notifications/Message';

interface IActionButtonBaseProps {
  text: string;
  disabled?: boolean;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  fontIcon?: string;
  size?: string;
  state?: boolean;
  color: buttonColors;
  tooltip?: string;
  className?: string;
  loadingText?: string;
}

// Extension for when showPopConfirm is false or not provided
interface IActionButtonWithoutConfirm extends IActionButtonBaseProps {
  showPopConfirm?: false;
  confirmText?: never;
}

interface IActionButtonWithConfirm extends IActionButtonBaseProps {
  showPopConfirm: true;
  confirmText: string;
}

type IActionButtonProps = IActionButtonWithoutConfirm | IActionButtonWithConfirm;

const ActionButton = forwardRef<HTMLButtonElement, IActionButtonProps>((props, ref) => {
  const {
    onClick,
    disabled,
    state,
    fontIcon,
    text,
    color,
    loadingText = '...Please wait',
    showPopConfirm = false,
    confirmText,
    ...rest
  } = props;

  const handleOnClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (showPopConfirm && confirmText) {
      const result = await Message('Confirm', confirmText, 'warning');
      if (result.isConfirmed) {
        props.onClick(e);
      }
    } else {
      props.onClick(e);
    }
  };

  return (
    <button
      ref={ref}
      type="button"
      className={clsx(`btn btn-${props.color} btn-sm btn-flex px-4 me-2 ${props.className && props.className}`, props.disabled && 'disabled', props.state && 'disabled')}
      data-toggle="tooltip"
      data-placement="bottom"
      title={props.tooltip}
      onClick={async (e) => { await handleOnClick(e); }}
      {...rest}
    >
      {!props.state &&
        (
          <span className="indicator-label">
            {props.fontIcon && <i className={clsx('fs-3  me-3', props.fontIcon)} />}
            {props.text}
          </span>
        )}
      {props.state && (
        <span className="indicator-progress" style={{ display: 'block', }}>
          {loadingText}
          <span className="spinner-border spinner-border-sm align-middle ms-2" />
        </span>
      )}
    </button>
  );
});

export default ActionButton;
