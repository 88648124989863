import { Modal, Spinner, Tab, Tabs } from 'react-bootstrap';
import { useListView } from '../../TenantListView';
import { useGetTenantByIdQuery } from '../../api/api';
import { type CreateEditTenantDto } from '../../api/Types';
import { getUserData } from '../../../../utility/Utils';
import { EditForm } from './EditForm';
import { BiHubForm } from './bi-hub/BiHubForm';

function EditModalWrapper() {
  const user = getUserData();

  // If ID === null it means we are in edit mode
  const { toggle, id, } = useListView();

  const { data, } = useGetTenantByIdQuery(id, {
    skip: !id,
  });

  const tenantForEdit: CreateEditTenantDto = {
    id: undefined,
    editionName: '',
    adminEmailAddress: user.email,
    adminFirstName: user.firstName,
    adminLastName: user.surName,
    editionId: undefined,
    isActive: true,
    name: '',
    powerBiUsername: '',
    powerBiPassword: '',
    projectName: '',
    tenantLogo: '',
    isMappingEnabled: false,
    powerBiWorkspaceUrl: '',
    powerBiServicePrincipalProfileName: '',
    isUsingPowerBiServicePrinciple: false,
    excludedTenantMappingColumnsList: [],
    databaseName: null,
    databaseServer: null,
    isUsingStorageAccount: false,
  };

  return (
    <Modal
      size="xl"
      tabIndex={-1}
      aria-hidden="true"
      show
      scrollable
      dialogClassName="modal-dialog modal-dialog-centered"
      onHide={() => { toggle(null); }}
      contentClassName="bg-transparent"
      backdrop="static"
    >
      <div className="modal-content">
        <Modal.Header closeButton className="border-bottom-0 pb-0">
          {!id ? <h1>Create company</h1> : <h1>Edit company</h1>}
        </Modal.Header>
        <div className="modal-body scroll-y mx-5">

          {
            id && data
              ? (
                <Tabs defaultActiveKey="general" transition className="mb-3">
                  <Tab eventKey="general" title="General">
                    <EditForm
                      tenantForEdit={data}
                      editMode
                    />
                  </Tab>
                  <Tab eventKey="biHub" title="BI Hub">
                    <BiHubForm />
                  </Tab>
                </Tabs>
              )
              : (
                <EditForm
                  tenantForEdit={tenantForEdit}
                  editMode={false}
                />
              )
          }
        </div>
      </div>
    </Modal>
  );
}

export { EditModalWrapper };
