import React, { type CSSProperties } from 'react';

export interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  active?: {
    fill: string;
    background: string;
  };
  cursor?: CSSProperties['cursor'];
}

export function Action({
  active, className, cursor, style, ...props
}: Props) {
  return (
    <button
      {...props}
      type="button"
      className="btn btn-secondary  w-100"
      tabIndex={0}
    />
  );
}
