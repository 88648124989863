import React, {
  useCallback, useMemo, useRef, useState
} from 'react';
import { AgGridReact } from 'ag-grid-react';
import { type ColDef } from 'ag-grid-community';
import { useParams } from 'react-router';
import { type MappingErrorDto } from './api/types';
import ActionButton from '../components/buttons/ActionButton';
import { useExecuteMappingStoredProcedureMutation } from './api/api';
import { Message } from '../../utility/notifications/Message';
import AppNotification from '../../utility/notifications/AppNotifications';

interface Props {
  data: MappingErrorDto[];
  filterProp: string;
}

const MissingMappingGrid: React.FC<Props> = ({ data, filterProp, }) => {
  const params = useParams();
  const [filterActive, setFilterActive] = useState(true);
  const [executeStoredProcedure, { isLoading, }] = useExecuteMappingStoredProcedureMutation();
  const gridRef = useRef<AgGridReact>(null);

  const [isChecked, setIsChecked] = useState(true);

  const toggleSwitch = () => { setIsChecked(!isChecked); };

  const refreshMissingMappings = async () => {
    await executeStoredProcedure(params.id)
      .unwrap()
      .then(() => {
        AppNotification.success(
          'Mapping refreshed',
          'Mapping refreshed successfully'
        );
      })
      .catch(async (error) => await Message(error.data.exception, error.data.messages[0], 'error'));
  };

  const columnDefs: ColDef[] = [
    { headerName: 'Database Name', field: 'databaseName', },
    { headerName: 'account_nr', field: 'accountId', sort: 'asc', },
    { headerName: 'general_ledger_name', field: 'description', },
    { headerName: 'entity_name', field: 'entity', },
    { headerName: 'name', field: 'name', },
    { headerName: 'division', field: 'division', }
  ];

  const onFirstDataRendered = useCallback(() => {
    if (gridRef.current) {
      gridRef.current.api.sizeColumnsToFit();
    }
  }, []);

  const rowData = filterActive
    ? data.filter((item) => item.databaseName === filterProp)
    : data;

  const defaultColDef = useMemo<ColDef>(() => ({
    sortable: true,
    width: 300,
  }), []);

  return (
    <>
      <div className="card-header border-0 pt-6">
        <input
          className="form-check-input"
          type="checkbox"
          id="flexSwitchCheckDefault"
          checked={isChecked}
          onChange={toggleSwitch}
        />
        <label className="form-check-label" htmlFor="flexSwitchCheckDefault">
          Show missing mappings
        </label>

        <div className="d-flex align-items-center">

          <ActionButton
            color="secondary"
            text={filterActive ? 'Clear Filter' : `Filter on the database name: ${filterProp}`}
            state={false}
            onClick={() => { setFilterActive(!filterActive); }}
          />

          <ActionButton
            color="primary"
            text="Refresh missing mappings"
            state={isLoading}
            onClick={refreshMissingMappings}
          />
        </div>

      </div>

      {isChecked &&
        (
          <div className="pt-4">
            <div className="ag-theme-alpine" style={{ height: '200px', width: '100%', }}>
              <AgGridReact
                enableRangeSelection
                columnDefs={columnDefs}
                rowData={rowData}
                onGridReady={onFirstDataRendered}
                defaultColDef={defaultColDef}
                rowHeight={25}
              />
            </div>
          </div>
        )}
    </>
  );
};

export default MissingMappingGrid;
