// Common permissions
export const Common = 'Common';

export const Common_Roles = 'Common.Roles';
export const Common_Roles_View = 'Common.Roles.View';
export const Common_Roles_Create = 'Common.Roles.Create';
export const Common_Roles_Edit = 'Common.Roles.Edit';
export const Common_Roles_Delete = 'Common.Roles.Delete';

export const Common_Users = 'Common.Users';
export const Common_Users_View = 'Common.Users.View';
export const Common_Users_Create = 'Common.Users.Create';
export const Common_Users_Edit = 'Common.Users.Edit';
export const Common_Users_Delete = 'Common.Users.Delete';

// Host permissions
export const Host = 'Host';

export const Host_Tenants = 'Host.Tenants';
export const Host_Tenants_View = 'Host.Tenants.View';
export const Host_Tenants_Create = 'Host.Tenants.Create';
export const Host_Tenants_Edit = 'Host.Tenants.Edit';
export const Host_Tenants_Delete = 'Host.Tenants.Delete';

export const Host_BiHub_View = 'Host.BiHub.View';

export const Host_MaterUsers_View = 'Host.MasterUsers.View';

export const Host_ImportFormSqlLogs_View = 'Host.ImportFormSqlLogs.View';
export const Host_DocumentCabinet_View = 'Host.DocumentCabinet.View';
export const Host_Mapping_View = 'Host.Mapping.View';

export const Host_Edition_View = 'Host.Editions.View';
export const Host_Edition_Edit = 'Host.Editions.Edit';

export const Tenant = 'Tenant';

export const Tenant_ManagementReports = 'Tenant.ManagementReports';
export const Tenant_ManagementReports_View = 'Tenant.ManagementReports.View';

export const Tenant_Dashboards = 'Tenant.Dashboards';

export const Tenant_Documents = 'Tenant.Documents';
export const Tenant_Documents_View = 'Tenant.Documents.View';

export const Tenant_Documents_Folders = 'Tenant.Documents.Folders';
export const Tenant_Documents_Folders_Create = 'Tenant.Documents.Folders.Create';
export const Tenant_Documents_Folders_Edit = 'Tenant.Documents.Folders.Edit';
export const Tenant_Documents_Folders_Move = 'Tenant.Documents.Folders.Move';
export const Tenant_Documents_Folders_Delete = 'Tenant.Documents.Folders.Delete';

export const Tenant_Documents_Files = 'Tenant.Documents.Files';
export const Tenant_Documents_Files_Upload = 'Tenant.Documents.Files.Upload';
export const Tenant_Documents_Files_Edit = 'Tenant.Documents.Files.Edit';
export const Tenant_Documents_Files_Move = 'Tenant.Documents.Files.Move';
export const Tenant_Documents_Files_Download = 'Tenant.Documents.Files.Download';
export const Tenant_Documents_Files_Delete = 'Tenant.Documents.Files.Delete';

export const Tenant_ImportForms = 'Tenant.ImportForms';
export const Tenant_ImportForms_View = 'Tenant.ImportForms.View';
export const Tenant_ImportForms_Edit = 'Tenant.ImportForms.Edit';

export const Tenant_BiHub_Run = 'Tenant.BiHub.Run';

export const Tenant_Mapping_View = 'Tenant.Mapping.View';
export const Tenant_Mapping_MissingMapping_view = 'Tenant.Mapping.MissingMapping_View';
export const Tenant_Mapping_Refresh = 'Tenant.Mapping.Refresh';
export const Tenant_Mapping_Edit = 'Tenant.Mapping.Edit';

export const Tenant_Forecasting_View = 'Tenant.Forecasting.View';
export const Tenant_Forecasting_Edit = 'Tenant.Forecasting.Edit';
